/* Modern gradient background with animation */
@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(
    -45deg, 
    #0f2027,
    #203a43,
    #2c5364,
    #2a0845,
    #6441A5
  );
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  color: rgba(255, 255, 255, 0.9);
  overflow-x: hidden;
}

/* Modern Glassmorphism Navbar */
.navbar {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 20px;
}

.navbar a {
  color: #fff !important;
  font-weight: 500;
  margin: 0 15px;
  transition: 0.3s;
  position: relative;
}

.navbar a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  transition: 0.3s;
}

.navbar a:hover::after {
  width: 100%;
}

/* Modern Cards with Hover Effects */
.project-card,
.blog-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 20px;
  transition: all 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  position: relative;
}

.project-card::before,
.blog-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.5s;
}

.project-card:hover::before,
.blog-card:hover::before {
  left: 100%;
}

.project-card:hover,
.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Modern Contact Form */
.contact-form {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 40px;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.contact-form textarea::placeholder,
.contact-form input::placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.contact-form input:focus,
.contact-form textarea:focus,
.contact-form select:focus {
  border-color: #ffcc00 !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 204, 0, 0.25) !important;
}

.contact-form button {
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.contact-form button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(255, 204, 0, 0.3);
}

/* Modern Page Headers */
.page-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.page-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.page-header h1 {
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;
  background: linear-gradient(45deg, #fff, #ffcc00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-header .lead {
  font-size: 1.5rem;
  position: relative;
  z-index: 1;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  border-radius: 5px;
}

/* Modern Footer Styles */
.footer-modern {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px 0 15px;
  margin-top: 50px;
}

.footer-content {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  padding: 20px;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.footer-content:hover {
  border-color: rgba(255, 204, 0, 0.2);
  transform: translateY(-2px);
}

.footer-content h5 {
  color: #ffffff;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
}

.footer-description, .contact-link, .location, .footer-links a, .copyright, .legal-links a {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-link, .location {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #ffcc00;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-links a {
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  display: inline-block;
}

.footer-links a:hover {
  color: #ffcc00;
  transform: translateX(5px);
}

.footer-bottom {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.copyright-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.copyright {
  font-size: 0.9rem;
}

.legal-links {
  display: flex;
  gap: 20px;
}

.legal-links a {
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: #ffcc00;
}

@media (max-width: 768px) {
  .footer-modern {
    padding: 20px 0 10px;
  }
  
  .copyright-wrapper {
    flex-direction: column;
    gap: 15px;
  }
}

.portfolio-container,
.blog-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 50px;
  padding: 50px;
}

.project-card,
.blog-card {
  text-align: center;
  width: 400px;
  box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.1);
}

.gradient-text {
  background: linear-gradient(45deg, #fff, #ffcc00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cta-button {
  display: inline-block;
  padding: 15px 40px;
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  color: #000;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 10px 20px rgba(255, 204, 0, 0.3);
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.cta-button:hover {
  color: #000;
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(255, 204, 0, 0.4);
}

.img-fluid {
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  object-fit: cover;
  width: 100%;
  height: 400px;
}

.img-fluid:hover {
  transform: scale(1.02);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.4);
}

.team-section {
  text-align: center;
  padding: 50px 0;
}

.team-member {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 20px;
  margin: 20px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.team-member h3 {
  color: #ffcc00;
  margin-bottom: 10px;
}

.team-member .title {
  color: #ff8800;
  font-weight: 500;
  margin-bottom: 20px;
}

.pricing-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 50px;
  padding: 50px;
}

.pricing-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
  height: 100%;
}

.pricing-card.recommended {
  border-color: #ffcc00;
  transform: scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.recommended-badge {
  position: absolute;
  top: -15px;
  right: 20px;
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  color: #000;
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.pricing-card h3 {
  color: #ffcc00;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.pricing-card .price {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #fff, #ffcc00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing-card .description {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
  font-size: 1.1rem;
}

.pricing-card .features-list {
  margin-bottom: 30px;
}

.pricing-card .feature-item {
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.pricing-card .feature-bullet {
  color: #ffcc00;
  font-weight: bold;
}

.custom-solution-cta {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 60px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Enhanced hero section styling */
.hero-section {
  padding: 60px 30px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
  margin-bottom: 80px;
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    rgba(255, 204, 0, 0.1) 0%,
    transparent 50%
  );
  animation: pulse 15s infinite;
  z-index: -1;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 0.2; }
  100% { transform: scale(1); opacity: 0.5; }
}

.cta-button-secondary {
  display: inline-block;
  padding: 15px 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid rgba(255, 204, 0, 0.3);
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.cta-button-secondary:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

/* Enhanced service cards */
.service-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 30px;
  height: 100%;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
}

.service-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #ffcc00, #ff8800);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.service-card:hover::after {
  transform: scaleX(1);
}

.service-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.service-icon {
  font-size: 3rem;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.service-card h3 {
  color: #ffcc00;
  margin-bottom: 15px;
}

.stat-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px 20px;
  height: 100%;
  transition: all 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 204, 0, 0.3);
}

.stat-card h3 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.stat-card p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.stats-section {
  padding: 50px 0;
}

/* For multiple images */
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media (max-width: 768px) {
  .hero-section {
    text-align: center;
    padding: 30px 0;
  }
  
  .hero-section img {
    margin-top: 30px;
  }
  
  .service-card {
    margin-bottom: 20px;
  }
  
  .stat-card {
    margin-bottom: 20px;
  }
}

/* Featured Projects */
.featured-project-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.featured-project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.featured-project-card .project-content {
  padding: 20px;
}

.featured-project-card h3 {
  color: white;
  margin-bottom: 15px;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.featured-project-card p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 15px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.tech-tag {
  background: rgba(255, 204, 0, 0.2);
  color: #ffcc00;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.tech-tag:hover {
  background: rgba(255, 204, 0, 0.3);
  transform: translateY(-2px);
}

.project-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
}

.project-content {
  padding: 25px;
}

.tech-stack {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tech-tag {
  background: rgba(255, 204, 0, 0.2);
  color: #ffcc00;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 0.9rem;
  backdrop-filter: blur(5px);
}

/* Testimonials */
.testimonial-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.testimonial-card::before {
  content: '"';
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 120px;
  line-height: 1;
  font-family: Georgia, serif;
  color: rgba(255, 204, 0, 0.1);
  z-index: 0;
}

.testimonial-content {
  position: relative;
  z-index: 1;
}

.author-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffcc00;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.author-info h4 {
  color: #ffcc00;
  margin: 0;
  font-size: 1.1rem;
}

.author-info p {
  margin: 0;
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Add a subtle animation to the gradient text */
.gradient-text {
  background-size: 200% auto;
  animation: textShine 5s linear infinite;
}

@keyframes textShine {
  to {
    background-position: 200% center;
  }
}

/* Service Detail Card */
.service-detail-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 30px;
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.service-detail-card h3 {
  color: white;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.service-detail-card p {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.service-icon-large {
  font-size: 3rem;
  color: #ffcc00;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 0 2px 10px rgba(255, 204, 0, 0.5);
}

.features-list {
  list-style: none;
  padding-left: 0;
  margin: 20px 0;
}

.feature-item {
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.feature-item i {
  color: #4CAF50;
  text-shadow: 0 1px 5px rgba(76, 175, 80, 0.5);
  margin-right: 10px;
}

.industry-text {
  color: rgba(255, 255, 255, 0.9);
  font-style: italic;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.industry-text i {
  color: #ffcc00;
  text-shadow: 0 2px 5px rgba(255, 204, 0, 0.5);
}

/* Process cards on Services page */
.process-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px;
  text-align: center;
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.process-card h4 {
  color: white;
  margin: 15px 0;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.process-card p {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.process-icon {
  color: #ffcc00;
  font-size: 2.5rem;
  margin-bottom: 15px;
  text-shadow: 0 2px 10px rgba(255, 204, 0, 0.5);
}

/* Value proposition cards */
.value-prop-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px;
  text-align: center;
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.value-prop-card h4 {
  color: white;
  margin: 15px 0;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.value-prop-card p {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.value-prop-card i {
  color: #ffcc00;
  font-size: 2.5rem;
  text-shadow: 0 2px 10px rgba(255, 204, 0, 0.5);
}

/* CTA Section */
.cta-section {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 60px !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 50px 0;
}

/* Contact Page Styles */
.contact-info-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 0.3s ease;
}

.contact-info-card:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 204, 0, 0.3);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.info-icon {
  font-size: 2rem;
  color: #ffcc00;
}

.info-content h3 {
  font-size: 1.1rem;
  color: #ffcc00;
  margin-bottom: 5px;
}

.info-content p,
.info-content a {
  margin: 0;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.info-content a:hover {
  color: #ffcc00;
}

.social-icon {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  padding: 10px 20px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.social-icon:hover {
  background: rgba(255, 204, 0, 0.1);
  border-color: #ffcc00;
  color: #ffcc00;
}

.contact-form .form-control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 12px;
  color: #fff;
  transition: all 0.3s ease;
}

.contact-form .form-control:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: #ffcc00;
  box-shadow: 0 0 15px rgba(255, 204, 0, 0.1);
}

.contact-form .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.submit-button {
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  border: none;
  padding: 15px 40px;
  border-radius: 30px;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  transition: all 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(255, 204, 0, 0.3);
}

.submit-button:disabled {
  background: linear-gradient(45deg, #4CAF50, #45a049);
  cursor: not-allowed;
}

@media (max-width: 992px) {
  .pricing-card.recommended {
    transform: scale(1);
  }
}

/* Portfolio Styles */
.portfolio-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.4s ease;
  height: 100%;
}

.portfolio-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.portfolio-image {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.portfolio-category {
  position: absolute;
  top: 20px;
  right: 20px;
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  color: #000;
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.portfolio-content {
  padding: 30px;
}

.portfolio-content h3 {
  color: #ffcc00;
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.portfolio-content .description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  margin-bottom: 20px;
  line-height: 1.6;
}

.results-section {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 20px 0;
}

.results-icon {
  font-size: 2rem;
}

.results-text {
  color: #ffcc00;
  font-weight: 600;
  font-size: 1.1rem;
}

.portfolio-cta {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 60px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .portfolio-image {
    height: 200px;
  }
  
  .portfolio-content h3 {
    font-size: 1.5rem;
  }
}

/* Add to your existing styles */
.navbar-logo {
  height: 85px;
  width: auto;
  transition: transform 0.3s ease;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand:hover .navbar-logo {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .navbar-logo {
    height: 65px;
  }
}

/* Blog Styles */
.blog-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.4s ease;
  height: 100%;
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.blog-image {
  height: 250px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.blog-category {
  position: absolute;
  top: 20px;
  right: 20px;
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  color: #000;
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.blog-content {
  padding: 30px;
}

.blog-meta {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.blog-meta .dot {
  margin: 0 10px;
}

.blog-content h3 {
  color: #ffcc00;
  font-size: 1.5rem;
  margin-bottom: 15px;
  line-height: 1.4;
}

.blog-content .description {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  line-height: 1.6;
}

.read-more {
  color: #ffcc00;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.read-more .arrow {
  transition: transform 0.3s ease;
}

.blog-card:hover .read-more .arrow {
  transform: translateX(5px);
}

.newsletter-section {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 60px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.newsletter-form .input-group {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.newsletter-form input {
  background: transparent;
  border: none;
  color: #fff;
  padding: 15px 25px;
}

.newsletter-form input:focus {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.05);
}

.newsletter-form input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 768px) {
  .blog-image {
    height: 200px;
  }
  
  .blog-content h3 {
    font-size: 1.3rem;
  }
}

/* Blog Post Styles */
.blog-post {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 50px;
}

.blog-post h2 {
  color: #ffcc00;
  margin: 30px 0 20px;
  font-size: 1.8rem;
}

.blog-post p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 1.1rem;
}

.blog-post .lead {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 30px;
}

.blog-post ul {
  color: rgba(255, 255, 255, 0.9);
  margin: 20px 0;
  padding-left: 20px;
}

.blog-post li {
  margin: 10px 0;
  line-height: 1.6;
}

.blog-post strong {
  color: #ffcc00;
}

/* Add these to your existing blog post styles */
.example-box, .insight-box, .tip-box {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.insight-box {
  border-left: 3px solid #ffcc00;
}

.tip-box {
  border-left: 3px solid #00ccff;
}

.benefit-list, .steps-list {
  list-style: none;
  padding: 0;
}

.benefit-list li, .steps-list li {
  margin: 15px 0;
  padding-left: 30px;
  position: relative;
}

.blog-content h3 {
  color: #ffcc00;
  font-size: 1.4rem;
  margin: 25px 0 15px;
}

.cta-section {
  background: linear-gradient(45deg, rgba(255, 204, 0, 0.1), rgba(255, 136, 0, 0.1));
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  margin-top: 50px;
}

.cta-section h3 {
  color: #ffcc00;
  margin-bottom: 20px;
}

/* Legal Pages Styles */
.legal-content {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 50px;
}

.legal-section {
  margin-bottom: 40px;
}

.legal-section h2 {
  color: #ffcc00;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.legal-section p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
  margin-bottom: 20px;
}

.legal-section ul {
  color: rgba(255, 255, 255, 0.9);
  margin: 20px 0;
  padding-left: 20px;
}

.legal-section li {
  margin: 10px 0;
  line-height: 1.6;
}

/* Newsletter Popup Styles */
.newsletter-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.newsletter-popup {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 40px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-button:hover {
  color: #ffcc00;
  transform: rotate(90deg);
}

.popup-icon {
  font-size: 3rem;
  color: #ffcc00;
  margin-bottom: 20px;
}

.newsletter-popup h3 {
  color: #ffcc00;
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.newsletter-popup p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 25px;
}

.popup-input-group {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.popup-input-group input {
  flex: 1;
  padding: 12px 20px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
}

.popup-input-group input:focus {
  outline: none;
  border-color: #ffcc00;
  box-shadow: 0 0 10px rgba(255, 204, 0, 0.2);
}

.popup-input-group button {
  background: linear-gradient(45deg, #ffcc00, #ff8800);
  border: none;
  padding: 12px 25px;
  border-radius: 25px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.popup-input-group button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 204, 0, 0.3);
}

.newsletter-popup small {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.success-message {
  text-align: center;
}

.success-message i {
  font-size: 4rem;
  color: #4CAF50;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .newsletter-popup {
    padding: 30px 20px;
  }

  .popup-input-group {
    flex-direction: column;
  }

  .popup-input-group button {
    width: 100%;
    justify-content: center;
  }
}

/* Work With Us Page Styles */
.benefit-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.benefit-icon {
  color: #ffcc00;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 0 2px 10px rgba(255, 204, 0, 0.5);
}

.process-section {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 40px;
}

.process-step-card {
  margin-bottom: 25px;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.process-step-icon {
  color: #ffcc00;
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.process-step-icon i {
  text-shadow: 0 2px 10px rgba(255, 204, 0, 0.5);
}

.process-points {
  list-style: none;
  padding: 0;
  margin: 0;
}

.process-points li {
  margin-bottom: 10px;
  font-size: 1.05rem;
}

.process-points li i {
  color: #4CAF50;
  text-shadow: 0 1px 5px rgba(76, 175, 80, 0.5);
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.cta-button-outline {
  background: transparent;
  border: 2px solid #ffcc00;
  color: #ffcc00;
  padding: 12px 30px;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.cta-button-outline:hover {
  background: #ffcc00;
  color: #000;
}

@media (max-width: 768px) {
  .process-step-card {
    text-align: center;
  }
  
  .process-step-icon {
    margin-bottom: 20px;
  }
}

/* About Page Styles */
.about-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 30px;
  height: 100%;
  transition: all 0.3s ease;
}

.values-list {
  list-style: none;
  padding: 0;
}

.values-list li {
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.approach-section {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.approach-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px;
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.approach-card h4 {
  color: white;
  margin: 15px 0;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.approach-card p {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.approach-icon {
  color: #ffcc00;
  font-size: 2.5rem;
  margin-bottom: 15px;
  text-shadow: 0 2px 10px rgba(255, 204, 0, 0.5);
}

.team-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.team-image {
  position: relative;
  height: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  transition: transform 0.5s ease;
}

.team-content {
  padding: 25px;
}

.team-role {
  color: #ffcc00;
  margin-bottom: 15px;
}

.team-social {
  margin-top: 20px;
}

.social-icon {
  display: inline-block;
  margin-right: 15px;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
}

.social-icon:hover {
  color: #ffcc00;
  transform: translateY(-3px);
}

/* Enhanced About Page Styles */
.about-card.gradient-border {
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 30px;
  height: 100%;
  transition: all 0.4s ease;
  z-index: 1;
  border: 1px solid transparent;
  background-clip: padding-box;
}

.about-card.gradient-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #ffcc00, #ff8800, #ff3366, #9933ff);
  z-index: -1;
  border-radius: 22px;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.about-card.gradient-border:hover::before {
  opacity: 1;
}

.team-card.enhanced {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.team-card.enhanced .team-header {
  position: relative;
}

.team-card.enhanced .team-image {
  position: relative;
  height: 450px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-card.enhanced .team-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  color: #fff;
  z-index: 2;
  background: linear-gradient(to top, rgba(0,0,0,0.9) 70%, transparent);
}

.team-card.enhanced .team-content {
  padding: 25px;
  padding-top: 30px;
  position: relative;
  z-index: 1;
}

.team-specialties {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 15px 0;
}

.specialty-tag {
  background: rgba(255, 204, 0, 0.2);
  color: #ffcc00;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  transition: all 0.3s ease;
  display: inline-block;
  margin-bottom: 5px;
}

.specialty-tag:hover {
  background: rgba(255, 204, 0, 0.3);
  transform: translateY(-2px);
}

.cta-button.pulse {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.7);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 204, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 204, 0, 0);
  }
}

.particles-container {
  position: relative;
  overflow: hidden;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 25% 25%, rgba(255, 204, 0, 0.2) 1px, transparent 1px),
    radial-gradient(circle at 75% 75%, rgba(255, 204, 0, 0.2) 1px, transparent 1px);
  background-size: 50px 50px;
  animation: particleMove 20s linear infinite;
  z-index: 0;
}

@keyframes particleMove {
  0% { background-position: 0 0; }
  100% { background-position: 50px 50px; }
}

/* Mobile Optimization */
@media (max-width: 768px) {
  /* General mobile adjustments */
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  /* Header adjustments */
  .page-header h1 {
    font-size: 2.5rem;
  }
  
  .page-header .lead {
    font-size: 1.1rem;
  }
  
  /* Card adjustments */
  .project-card,
  .blog-card,
  .service-card,
  .about-card,
  .team-card {
    padding: 20px;
  }
  
  /* Button adjustments */
  .cta-button {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .cta-buttons {
    flex-direction: column;
  }
  
  /* Navigation adjustments */
  .navbar-collapse {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .navbar-nav .nav-item {
    margin-bottom: 10px;
  }
  
  /* Home hero section */
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .hero-content p {
    font-size: 1rem;
  }
  
  /* About page */
  .team-image {
    height: 200px;
  }
  
  /* Portfolio page */
  .filter-buttons .btn {
    margin-bottom: 10px;
  }
  
  /* Services page */
  .service-icon {
    margin-bottom: 15px;
  }
  
  /* Footer adjustments */
  .footer-content {
    margin-bottom: 20px;
  }
}

/* Small mobile devices */
@media (max-width: 576px) {
  .page-header h1 {
    font-size: 2rem;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
  
  .hero-content h1 {
    font-size: 2rem;
  }
  
  .stat-card h3 {
    font-size: 2rem;
  }
}

/* Adjust the overlay to match the new image height */
.team-card.enhanced .image-overlay {
  height: 30%;
}

/* Fix team card layout issues */
.team-card.enhanced .team-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  color: #fff;
  z-index: 2;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 70%, transparent);
  padding-bottom: 25px;
}

.team-card.enhanced .team-content {
  padding: 25px;
  padding-top: 30px;
  position: relative;
  z-index: 1;
}

.team-specialties {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 15px 0;
}

/* Make sure the specialty tags are clearly visible */
.specialty-tag {
  background: rgba(255, 204, 0, 0.2);
  color: #ffcc00;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  transition: all 0.3s ease;
  display: inline-block;
  margin-bottom: 5px;
}

/* Improve text readability on gradient backgrounds */
p, .lead, li {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-weight: 400;
}

/* Add subtle text backgrounds to improve readability on gradient sections */
.hero-section, .services-preview, .featured-projects-section, .testimonials-section {
  position: relative;
}

.hero-section::before, .services-preview::before, 
.featured-projects-section::before, .testimonials-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  z-index: -1;
}

/* Ensure cards have proper backgrounds for text */
.service-card, .stat-card, .featured-project-card, .testimonial-card, .blog-card, .project-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

/* Ensure gradient text is highly visible */
.gradient-text {
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Improve contrast for links */
a:not(.cta-button):not(.cta-button-secondary) {
  color: #ffcc00;
  font-weight: 500;
}

/* Ensure buttons have good contrast */
.cta-button, .cta-button-secondary {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

/* Additional improvements for text readability */
.process-step-card, .benefit-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Ensure all text is highly visible */
p, .lead, li, .process-points li, .benefit-card p {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-weight: 400;
}

/* Make headings more visible */
h1, h2, h3, h4, h5, h6 {
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Specific improvements for the Get Started page */
.process-step-card {
  margin-bottom: 25px;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.process-step-icon {
  color: #ffcc00;
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.process-step-icon i {
  text-shadow: 0 2px 10px rgba(255, 204, 0, 0.5);
}

.process-points li {
  margin-bottom: 10px;
  font-size: 1.05rem;
}

.process-points li i {
  color: #4CAF50;
  text-shadow: 0 1px 5px rgba(76, 175, 80, 0.5);
}

/* Improve benefit cards */
.benefit-card {
  padding: 30px;
  border-radius: 15px;
  height: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.benefit-card h3 {
  color: white;
  margin-bottom: 15px;
}

/* Tech bubbles for home page */
.tech-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.tech-bubble {
  position: absolute;
  background: rgba(255, 204, 0, 0.15);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 0.9rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  animation: float 15s infinite ease-in-out;
}

.tech-bubble:nth-child(1) {
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}

.tech-bubble:nth-child(2) {
  top: 70%;
  left: 20%;
  animation-delay: 2s;
}

.tech-bubble:nth-child(3) {
  top: 40%;
  left: 80%;
  animation-delay: 4s;
}

.tech-bubble:nth-child(4) {
  top: 80%;
  left: 70%;
  animation-delay: 6s;
}

.tech-bubble:nth-child(5) {
  top: 10%;
  left: 60%;
  animation-delay: 8s;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

/* Featured section styling */
.featured-section {
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  margin-top: 80px;
  margin-bottom: 80px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.featured-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    rgba(255, 204, 0, 0.05) 0%,
    transparent 50%
  );
  animation: pulse 15s infinite;
  z-index: -1;
}

/* Trusted by section */
.trusted-by {
  text-align: center;
  margin: 60px 0;
}

.trusted-by h3 {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.client-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.client-logo {
  opacity: 0.7;
  filter: grayscale(100%);
  transition: all 0.3s ease;
  max-width: 120px;
  height: auto;
}

.client-logo:hover {
  opacity: 1;
  filter: grayscale(0%);
  transform: scale(1.1);
}

/* Add these styles to your GlobalStyles.css */

.modern-header {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.modern-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(44, 83, 100, 0.8), rgba(15, 32, 39, 0.95));
  z-index: -1;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.main-title {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.cursor {
  display: inline-block;
  width: 3px;
  height: 1em;
  background-color: #ffcc00;
  margin-left: 5px;
  vertical-align: text-bottom;
}

.cursor.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
}

.header-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 2rem;
}

/* Data Benefits Section Styling */
.data-benefits-section {
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  margin-top: 80px;
  margin-bottom: 80px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.benefit-card {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
  height: 100%;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.benefit-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.benefit-icon {
  font-size: 2.5rem;
  margin-bottom: 20px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 20px;
}

.insight-card .benefit-icon {
  background: linear-gradient(135deg, #ffcc00, #ff8800);
  color: #000;
}

.prediction-card .benefit-icon {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #fff;
}

.optimization-card .benefit-icon {
  background: linear-gradient(135deg, #00f260, #0575e6);
  color: #fff;
}

.customer-card .benefit-icon {
  background: linear-gradient(135deg, #f857a6, #ff5858);
  color: #fff;
}

.benefit-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #fff;
}

.benefit-card p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
}

.benefit-stat {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 5px;
}

.insight-card .stat-number {
  background: linear-gradient(to right, #ffcc00, #ff8800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.prediction-card .stat-number {
  background: linear-gradient(to right, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.optimization-card .stat-number {
  background: linear-gradient(to right, #00f260, #0575e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.customer-card .stat-number {
  background: linear-gradient(to right, #f857a6, #ff5858);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-text {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 1px;
} 